<template>
  <v-row class="pa-3">
    <v-col cols="12">
      <v-card class="pb-2">
        <v-card-title>
          <label class="font-weight-bold grey--text text--lighten-1 body-2"
            >RIWAYAT TREATMENT</label
          >
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            tile
            color="red"
            v-if="filter_open"
            class="mx-0 white--text"
            @click="reset()"
            >RESET</v-btn
          >
          <v-btn
            depressed
            tile
            color="#F4F5F5"
            @click="filterOpen()"
            class="mx-3"
          >
            <img src="@/assets/image/ic_filter.png" class="mr-2" />
            <span class="black--text text-capitalize">Filters</span>
          </v-btn>

          <v-text-field
            placeholder="Cari jenis pemeriksaan"
            solo
            flat
            prepend-inner-icon="mdi-magnify"
            hide-details
            class="search pt-1"
            v-model="search"
          />
        </v-card-title>
        <v-row v-if="filter_open" class="pb-2 px-3">
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Tanggal Pemeriksaan</label>
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="datepicker"
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="date.formatted"
                  v-on="on"
                  readonly
                  placeholder="Tanggal"
                  outlined
                  dense
                  hide-details="false"
                  single-line
                ></v-text-field>
              </template>
              <v-date-picker
                multiple
                v-model="selectedDate"
                @input="changedate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col align-self-center class="pt-8 px-0" style="max-width: 30px">
            <v-btn icon class="ma-0">
              <v-icon>mdi-swap-vertical</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Unit</label>
            <v-combobox
              placeholder="Semua Unit"
              outlined
              dense
              hide-details="false"
              single-line
              :items="unit"
              item-text="name"
              v-model="unitFilter"
              clearable
              @change="getCheckups()"
            />
          </v-col>
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Dokter</label>
            <v-combobox
              placeholder="Semua Dokter"
              outlined
              dense
              hide-details="false"
              single-line
              :items="doctor"
              item-text="name"
              v-model="doctorFilter"
              clearable
              @change="getCheckups()"
            />
          </v-col>
        </v-row>

        <template v-for="(action, index) in resource.checkups">
          <v-card
            flat
            class="card-visited text-left pa-3"
            :key="'visited-' + index"
          >
            <label class="font-weight-bold">Kunjungan {{ index + 1 }}</label>
          </v-card>
          <v-expansion-panels :key="'action-' + index">
            <v-expansion-panel class="mb-4">
              <v-expansion-panel-header style="background-color: #ececec">
                <div>
                  <label class="font-weight-bold pr-3 primary--text">{{
                    action.date
                  }}</label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background-color: #ececec">
                <v-card flat color="#DEEEF9">
                  <v-timeline dense align-top>
                    <v-timeline-item
                      v-for="(item, index) in action.checkup"
                      :key="'item' + index"
                      color="primary"
                      small
                    >
                      <v-card
                        flat
                        color="#F0F9FF"
                        class="mr-4 px-3 mb-5"
                        style="border-left: 3px solid #429ddb"
                      >
                        <v-row class="mb-4">
                          <v-col class="text-left" cols="1">
                            <label class="font-weight-medium">{{
                              item.dateSplit[1]
                            }}</label>
                            <br />
                            <label class="caption grey--text text--darken-2">{{
                              item.unit
                            }}</label>
                          </v-col>
                          <v-col cols="3" class="ma-0 text-left">
                            <label class="font-weight-medium"
                              >Dokter : {{ item.doctor }}</label
                            >
                          </v-col>
                          <v-col
                            class="text-left"
                            v-if="item.planning.treatment_plan"
                          >
                            <label class="caption grey--text"
                              >Rencana Perawatan :</label
                            >
                            <p
                              for="treatment"
                              class="caption font-weight-medium"
                            >
                              {{ item.planning.treatment_plan }}
                            </p>
                          </v-col>
                          <v-col
                            :cols="item.planning.treatment_plan ? 4 : 8"
                            class="caption text-left font-weight-medium"
                          >
                            <label class="caption grey--text">Tindakan :</label>
                            <ul>
                              <template
                                v-for="(detail, indexing) in item.action.filter(
                                  x => x !== '',
                                )"
                              >
                                <li
                                  :key="'docPlan' + indexing"
                                  class="font-weight-medium caption"
                                >
                                  {{ detail }}
                                </li>
                              </template>
                            </ul>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4"></v-col>
                          <v-col cols="4" class="text-left"
                            ><v-row
                              v-if="
                                item.others &&
                                  item.others.radiology.some(i =>
                                    lodashGet(i, 'files.files[0]', null),
                                  )
                                //   item.others.radiology.some(i => i.files) &&
                                //   item.others.radiology.some(
                                //     i => i.files.files.length > 0,
                                //   )
                              "
                              ><v-col>
                                <v-row class="mb-2 pl-3"
                                  ><label class="caption grey--text mb-1"
                                    >Hasil Foto Radiologi</label
                                  ></v-row
                                >
                                <img
                                  v-for="(image,
                                  index) in item.others.radiology.filter(
                                    i => i.files?.files?.length > 0,
                                  )"
                                  :key="`img-${index}`"
                                  width="130"
                                  @click="
                                    openImage(item.others.radiology);
                                    carousel = index;
                                  "
                                  height="75"
                                  class="mr-2"
                                  style="cursor: pointer"
                                  :src="imgUrl(`/${image.files.files[0].path}`)"
                                  :alt="
                                    image.files.files[0].original_file_name
                                  "/></v-col></v-row
                          ></v-col>
                          <v-col cols="4" class="text-left">
                            <v-row
                              v-if="
                                lodashGet(
                                  item,
                                  'others.intraoral[0].files.files',
                                  null,
                                )
                              "
                              ><v-col
                                ><v-row class="mb-2 pl-3"
                                  ><label class="caption grey--text"
                                    >Foto Intra Oral</label
                                  ></v-row
                                >
                                <img
                                  v-for="(image, index) in item.others
                                    .intraoral[0].files.files"
                                  :key="`img-${index}`"
                                  @click="
                                    openImage(
                                      item.others.intraoral[0].files,
                                      'oral',
                                    );
                                    carousel = index;
                                  "
                                  width="90"
                                  height="75"
                                  class="mr-2"
                                  style="cursor: pointer"
                                  :src="imgUrl(`/${image.path}`)"
                                  :alt="image.original_file_name"/></v-col
                            ></v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <div v-if="resource?.checkups?.length === 0">
          <v-alert :value="true" type="info" class="mx-3">
            Maaf data riwayat tindakan tidak ditemukan
          </v-alert>
        </div>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" light content-class="elevation-0">
      <v-btn
        width="100"
        text
        small
        @click="downloadImage(previewImage[carousel])"
        class="download-btn white--text text-capitalize"
        ><v-icon color="white" left>mdi-download</v-icon>Download</v-btn
      >
      <v-btn text @click="dialog = false" fab small class="close-btn"
        ><v-icon color="white">mdi-close</v-icon></v-btn
      >
      <div class="preview-image">
        <v-carousel hide-delimiters v-model="carousel">
          <v-carousel-item
            v-for="(item, index) in previewImage"
            :key="`img-${index}`"
          >
            <img :width="450" :src="imgUrl(`/${item.path}`)" :alt="item.name" />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="image-list">
        <v-slide-group active-class="success" v-model="carousel" show-arrows>
          <v-slide-item
            v-for="(item, index) in previewImage"
            :key="`img-${index}`"
          >
            <img
              width="75"
              height="65"
              class="mr-2"
              style="cursor: pointer"
              @click="carousel = index"
              :class="{
                'darken-image': index !== carousel,
              }"
              :src="imgUrl(`/${item.path}`)"
              :alt="item.name"
            />
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'ActionComponent',
  props: {
    id_emr: {
      type: String,
    },
    unit: { type: Array },
    doctor: { type: Array },
  },
  data() {
    return {
      carousel: '',
      dialog: false,
      filter_open: false,
      date: {
        raw: [],
        formatted: moment().format('DD-MM-YYYY'),
      },
      selectedDate: [],
      checkups: [],
      resource: {
        checkups: [],
      },
      unitFilter: '',
      doctorFilter: '',
      search: '',
      datepicker: false,
      previewImage: [],
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  mounted() {
    this.getCheckups();
  },
  methods: {
    lodashGet(data, path, def) {
      return _.get(data, path, def);
    },
    downloadImage(data) {
      fetch(this.imgUrl(`/${data.path}`))
        .then(resp => resp.blob())
        .then(blobobject => {
          const blob = window.URL.createObjectURL(blobobject);
          const anchor = document.createElement('a');
          anchor.style.display = 'none';
          anchor.href = blob;
          anchor.download = data.name;
          document.body.appendChild(anchor);
          anchor.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Gagal mengunduh file',
          });
        });
    },
    openImage(array, type) {
      this.dialog = true;
      if (type === 'oral') {
        this.previewImage = array.files.map(item => {
          return {
            path: item.path,
            name: item.original_file_name,
          };
        });
      } else {
        this.previewImage = array
          .filter(item => item.files?.files?.length > 0)
          .map(item => {
            return {
              path: item.files.files[0].path,
              name: item.files.files[0].original_file_name,
            };
          });
      }
    },
    imgUrl(path) {
      return Constant.apiUrl.concat(path);
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getCheckups();
    }, 500),
    filterOpen() {
      this.filter_open = !this.filter_open;
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.datepicker = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
      this.getCheckups();
    },
    reset() {
      this.date.raw = [];
      this.date.formatted = moment().format('DD-MM-YYYY');
      this.unitFilter = null;
      this.doctorFilter = null;
      this.getCheckups();
    },
    getCheckups() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/checkup/${this.id_emr}?startDate=${this.date.raw[0] ||
              ''}&endDate=${this.date.raw[1] || ''}&unit=${
              this.unitFilter ? this.unitFilter.name : ''
            }&doctor=${
              this.doctorFilter ? this.doctorFilter.name : ''
            }&search=${this.search}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          let finalArray = [];
          const sortedData = data.sort(
            (a, b) => moment(a.date) - moment(b.date),
          );
          sortedData.forEach(item => {
            if (finalArray.length === 0) {
              finalArray.push({
                date: item.date,
                checkup: [...item.checkup],
              });
              return;
            }
            if (finalArray.some(i => i.date === item.date)) {
              const temp = finalArray.find(x => x.date === item.date);
              const index = finalArray.indexOf(temp);
              finalArray[index].checkup.push(...item.checkup);
              return;
            }
            finalArray.push({
              date: item.date,
              checkup: [...item.checkup],
            });
          });
          this.resource.checkups = finalArray;
        })
        .catch(() => {
          this.resource.checkups = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  width: 90vw;
  height: 90vh;
  margin: 0px;
  padding-left: 7vw;
  padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-list {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  padding-bottom: 10px;
  padding-right: 3rem;
  .darken-image {
    filter: brightness(50%);
  }
}
.search {
  border: 1px solid #9ca2a5;
  border-radius: 3px;
  height: 40px;
}
.card-visited {
  border: 1px solid #429ddb;
}
.tindakan {
  display: grid;
  grid-template-columns: 300px 500px;
  grid-row-gap: 20px;
}
::v-deep .v-input__slot {
  min-height: 0px !important;
}
::v-deep .v-text-field.v-text-field--enclosed .v-input__append-inner,
.v-text-field.v-text-field--enclosed .v-input__append-outer,
.v-text-field.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field.v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 5px;
}
::v-deep input {
  margin-top: 0px !important;
}
::v-deep .v-timeline-item .v-card:after,
.v-timeline-item .v-card:before {
  content: none;
}
</style>
